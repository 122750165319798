import React from 'react';
import styles from '../css/checkout.module.scss';
import { ReactComponent as Logo } from '../media/pivot-logo-dark.svg';

const Header = React.memo(function Header() {
  return (
    <header className={styles.header}>
      <Logo />
    </header>
  );
});
export default Header;
