import { Outlet, ReactLocation, Router } from '@tanstack/react-location';
import Header from './components/header';
import NotificationBar from './components/notification-bar';
import SuspensePreloader from './components/suspense-preloader';
import { apiRootEndpoint } from './config';
import { CheckoutContextProvider } from './context/context';
import Activated from './pages/activated';
import ErrorPage from './pages/error';
import PlanCheckout from './pages/plan-checkout';
import { Product } from './types/types';
import { trackPageViewInBraze } from './utils/braze-helper';
import { trackPageViewInGA } from './utils/google-analytics-helper';
import { trackPageViewInMixpanel } from './utils/mixpanel-helper';

const location = new ReactLocation();

export default function App(): JSX.Element {
  const trackPageView = (pathname: string): void => {
    trackPageViewInBraze(pathname);
    trackPageViewInGA(pathname);
    trackPageViewInMixpanel(pathname);
  };

  return (
    <Router
      location={location}
      defaultPendingElement={<SuspensePreloader />}
      routes={[
        {
          path: '/plan/:stripePriceId',
          element: <PlanCheckout />,
          /* errorElement: <ErrorPage />, // lib bug @ https://github.com/TanStack/router/issues/255*/
          loader: async routeMatch => {
            trackPageView(routeMatch.pathname);
            const stripePriceId: string = routeMatch.params.stripePriceId;
            // lib bug @ https://github.com/TanStack/router/issues/255
            if (!/^price_[a-zA-Z\d]*$/gi.test(stripePriceId)) {
              // workaround until throw new Error('MalformedPriceId') can be used
              return {
                error: 'MalformedPriceId',
                stripePriceId,
                nicTypes: routeMatch.search?.type,
                currentProduct: {},
              };
            }

            const req = await fetch(
              `${apiRootEndpoint}/v1/products?priceIds[]=${stripePriceId}`
            );
            const res = await req.json();
            const currentProduct: Product = res.data.products.find(
              (product: Record<string, any>) => {
                return product.stripePriceId === stripePriceId;
              }
            );
            return {
              stripePriceId,
              nicTypes: routeMatch.search?.type,
              currentProduct,
            };
          },
        },
        {
          path: '/activated',
          element: <Activated />,
          loader: async routeMatch => {
            trackPageView(routeMatch.pathname);
            return {
              ...routeMatch.search,
            };
          },
        },
        {
          path: '/error',
          element: <ErrorPage />,
          loader: async routeMatch => {
            trackPageView(routeMatch.pathname);
            return {
              ...routeMatch.search,
            };
          },
        },
      ]}
    >
      <CheckoutContextProvider>
        <NotificationBar />
        <Header />
        <Outlet />
      </CheckoutContextProvider>
    </Router>
  );
}
