import styles from '../css/suspensePreloader.module.scss';
import Spinner from './spinner';

export default function SuspensePreloader() {
  return (
    <div className={styles.suspensePreloader}>
      <Spinner />
    </div>
  );
}
