import React, { useMemo } from 'react';
import styles from '../css/checkout.module.scss';
import { FEATURE_NAMES } from '../plans';
import { getPlanActiveFeatures } from '../utils/stripe-plan-utils';

type TestimonialsProps = {
  stripePriceId: string;
};

export const TestimonialsTestIds = {
  testimonialsTitle: 'testimonialsTitle',
  quotesBlock: 'quotesBlock',
};

const Testimonials = React.memo(function Testimonials({
  stripePriceId,
}: TestimonialsProps): JSX.Element {
  const features: string[] = getPlanActiveFeatures(stripePriceId);

  const planHasSensor: boolean = useMemo(() => {
    return features.some(feature => {
      return feature === FEATURE_NAMES.sensor;
    });
  }, [features]);

  const planHasCoaching: boolean = useMemo(() => {
    return features.some(feature => feature === FEATURE_NAMES.coach);
  }, [features]);

  const filteredTestimonials: Testimonial[] = useMemo(() => {
    return TESTIMONIALS.filter(item => {
      if (!planHasSensor && !planHasCoaching) {
        return !item.hasSensor && !item.hasCoaching;
      }
      return (
        (planHasSensor && item.hasSensor) ||
        (planHasCoaching && item.hasCoaching)
      );
    });
  }, [planHasSensor, planHasCoaching]);

  return (
    <aside
      className={styles.testimonials}
      data-testid={TestimonialsTestIds.quotesBlock}
    >
      <h3
        data-testid={TestimonialsTestIds.testimonialsTitle}
        className={styles.sectionTitle}
      >
        What our customers say…
      </h3>
      {filteredTestimonials.map((item: Testimonial): JSX.Element => {
        return (
          <blockquote key={item.name}>
            <div className={styles.citeAndName}>
              <p>“{item.text}”</p>
              <cite>{item.name}</cite>
            </div>
          </blockquote>
        );
      })}
    </aside>
  );
});

export default Testimonials;

type Testimonial = {
  name: string;
  text: string;
  hasSensor: boolean;
  hasCoaching: boolean;
};

const TESTIMONIALS: Testimonial[] = [
  {
    name: 'Jason L.',
    text: 'The Pivot sensor made a big difference for me. As I continued to see my goals manifest on that gadget it gave me confidence that I was progressing.',
    hasSensor: true,
    hasCoaching: false,
  },
  {
    name: 'Mari',
    text: 'With every goal I met [in Pivot] I learned how strong I was. My coach was with me whenever I needed her. With Pivot I went from 2 packs a day to zero after 37 years of smoking',
    hasSensor: false,
    hasCoaching: true,
  },
  {
    name: 'Katie',
    text: 'It absolutely works if you have resolved to quit or even cut back. And don’t be afraid of the expense. I consider it one of the wisest investments I’ve ever made for myself!',
    hasSensor: false,
    hasCoaching: false,
  },
];
