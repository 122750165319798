import { useMatch } from '@tanstack/react-location';
import clsx from 'clsx';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useCreateAccount from '../api/use-create-account';
import { useStripePaymentFlow } from '../api/use-stripe-payment-flow';
import EmailPrivacyNotification from '../components/email-privacy-notification';
import Checkbox from '../components/form-fields/checkbox';
import { EmailInputField } from '../components/form-fields/email-input-field';
import Input from '../components/form-fields/input';
import StatesDropdown, {
  INITIAL_DROPDOWN_PROMPT_VALUE,
} from '../components/form-fields/us-states-dropdown';
import FormFooter from '../components/form-footer';
import PasswordAndStrength from '../components/password-and-strength/password-and-strength';
import PersonalDetailsCard from '../components/personal-details-card';
import PlanCard from '../components/plan-card';
import Spinner, { SPINNER_COLORS } from '../components/spinner';
import StripeLogos from '../components/stripe-logos';
import TermsOfService from '../components/terms-of-service';
import Testimonials from '../components/testimonials';
import {
  CheckoutContextActions,
  RegistrationFieldNames,
  useCheckoutContext,
} from '../context/context';
import styles from '../css/checkout.module.scss';
import { NicType, Product } from '../types/types';
import {
  checkFormValidity,
  checkPersonalDetailsValidity,
} from '../utils/checkout-form-validations';
import { ADDICTION_TYPES_URL_PARAM } from '../utils/constants';
import { isMobile } from '../utils/is-mobile';
import {
  validateCity,
  validateMaxStringLength,
  validateName,
  validateStreetAddress,
  validateZip,
} from '../utils/validation';

export default function PlanCheckout(): JSX.Element {
  const { data } = useMatch();
  const nicTypes: string[] =
    ADDICTION_TYPES_URL_PARAM[data.nicTypes as NicType];
  const stripePriceId: string = data.stripePriceId as string;
  const currentProduct: Product = data.currentProduct as Product;
  const { createAccount } = useCreateAccount();
  const { initPaymentFlow } = useStripePaymentFlow();
  const shippingSectionRef = useRef<HTMLDivElement>(null);
  const needsShipping = currentProduct.productSummary?.sensor === true;
  const [isShowingShipping, setIsShowingShipping] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { state, dispatch } = useCheckoutContext();

  const proceedToShippingButtonCondition: boolean =
    isMobile && needsShipping && !isShowingShipping;

  const shippingSectionCondition: boolean =
    isShowingShipping || (!isMobile && needsShipping);

  const legalSectionCondition: boolean =
    isShowingShipping || (isMobile && !needsShipping);

  const isValidForSubmission: boolean = useMemo(() => {
    return checkFormValidity(state, needsShipping);
  }, [state, needsShipping]);

  const canProceedToShipping: boolean = useMemo(() => {
    return checkPersonalDetailsValidity(state);
  }, [state]);

  useEffect(() => {
    if (data.error) {
      dispatch({ type: CheckoutContextActions.showError });
    }
  }, [data.error, dispatch]);

  const submitCondition = (): boolean | undefined => {
    if (isMobile) {
      if (needsShipping && isShowingShipping) {
        return true;
      }
      if (!needsShipping) {
        return true;
      }
    }
    // in desktop, we always show "submit + legal"
    if (!isMobile) {
      return true;
    }
  };

  const proceedToShipping = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
    setIsShowingShipping(true);
  };

  useLayoutEffect(() => {
    if (isShowingShipping && shippingSectionRef?.current) {
      shippingSectionRef.current.scrollIntoView();
    }
  }, [isShowingShipping, shippingSectionRef]);

  const editPersonalDetails = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
    setIsShowingShipping(false);
  };

  const submit = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    event.preventDefault();
    if (!isValidForSubmission || isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const payload = { ...state, nicTypes, stripePriceId };
    const userId = await createAccount(payload);
    initPaymentFlow({ ...payload, userId });
  };

  return (
    <div className={styles.columns}>
      <div
        className={styles.chosenPlanWrapper}
        data-is-mobile={isMobile.toString()}
        data-needs-shipping={needsShipping.toString()}
      >
        <PlanCard stripePriceId={stripePriceId} product={currentProduct} />
        <StripeLogos />
        <Testimonials stripePriceId={stripePriceId} />
      </div>

      <div className={styles.checkoutFormWrapper}>
        <form className={styles.checkoutForm}>
          <section
            className={clsx(styles.editPersonalDetailsSection, {
              [styles.isShowing]: !shippingSectionCondition,
              [styles.isMobile]: isMobile,
            })}
          >
            <h1 className={styles.sectionTitle}>Sign up with your email</h1>
            <EmailInputField
              placeholder="Email"
              value={state[RegistrationFieldNames.email].value ?? ''}
              name={RegistrationFieldNames.email}
              callback={useCallback(
                (value: string, isValid: boolean | undefined): void => {
                  dispatch({
                    type: CheckoutContextActions.setFieldValue,
                    field: RegistrationFieldNames.email,
                    value,
                    isValid,
                  });
                },
                [dispatch]
              )}
            />
            <EmailPrivacyNotification />
            <PasswordAndStrength
              value={state[RegistrationFieldNames.password].value ?? ''}
              name={RegistrationFieldNames.password}
              callback={useCallback(
                (value: string, isValid: boolean | undefined): void => {
                  dispatch({
                    type: CheckoutContextActions.setFieldValue,
                    field: RegistrationFieldNames.password,
                    value,
                    isValid,
                  });
                },
                [dispatch]
              )}
            />
            <div className="side-by-side-inputs mobile-stacked">
              <Input
                type="text"
                value={state[RegistrationFieldNames.firstName].value ?? ''}
                name={RegistrationFieldNames.firstName}
                placeholder="First Name"
                autoComplete="given-name"
                validator={(value: string) => validateName(value, 2, 16)}
                errorString="Invalid input"
                callback={useCallback(
                  (value: string, isValid: boolean | undefined): void => {
                    dispatch({
                      type: CheckoutContextActions.setFieldValue,
                      field: RegistrationFieldNames.firstName,
                      value,
                      isValid,
                    });
                  },
                  [dispatch]
                )}
              />
              <Input
                type="text"
                value={state[RegistrationFieldNames.lastName].value ?? ''}
                name={RegistrationFieldNames.lastName}
                placeholder="Last Name"
                autoComplete="family-name"
                validator={(value: string) => validateName(value, 2, 16)}
                errorString="Invalid input"
                callback={useCallback(
                  (value: string, isValid: boolean | undefined): void => {
                    dispatch({
                      type: CheckoutContextActions.setFieldValue,
                      field: RegistrationFieldNames.lastName,
                      value,
                      isValid,
                    });
                  },
                  [dispatch]
                )}
              />
            </div>
          </section>
          <section
            className={clsx(styles.shippingSection, {
              [styles.isMobile]: isMobile,
              [styles.isShowing]: shippingSectionCondition,
            })}
          >
            {isMobile && (
              <PersonalDetailsCard
                name={state?.firstName.value ?? ''}
                lastName={state?.lastName.value ?? ''}
                email={state?.email.value ?? ''}
                onClickHandler={editPersonalDetails}
              />
            )}
            <h1 className={styles.sectionTitle}>Your Shipping Address</h1>
            <Input
              type="text"
              value={state[RegistrationFieldNames.streetAddress1].value ?? ''}
              name={RegistrationFieldNames.streetAddress1}
              placeholder="Street and number, P.O. box, c/o."
              autoComplete="shipping address-line1"
              validator={validateStreetAddress}
              errorString="Invalid address"
              callback={useCallback(
                (value: string, isValid: boolean | undefined): void => {
                  dispatch({
                    type: CheckoutContextActions.setFieldValue,
                    field: RegistrationFieldNames.streetAddress1,
                    value,
                    isValid,
                  });
                },
                [dispatch]
              )}
            />

            <Input
              type="text"
              value={state[RegistrationFieldNames.streetAddress2].value ?? ''}
              name={RegistrationFieldNames.streetAddress2}
              placeholder="Apartment, suite, unit, building, floor, etc."
              autoComplete="shipping address-line2"
              validator={(value: string) =>
                value?.length > 0
                  ? validateMaxStringLength(value, 100)
                  : undefined
              }
              errorString="Invalid address"
              required={false}
              callback={useCallback(
                (value: string, isValid: boolean | undefined): void => {
                  dispatch({
                    type: CheckoutContextActions.setFieldValue,
                    field: RegistrationFieldNames.streetAddress2,
                    value,
                    isValid,
                  });
                },
                [dispatch]
              )}
            />

            <Input
              type="text"
              value={state[RegistrationFieldNames.city].value ?? ''}
              name={RegistrationFieldNames.city}
              placeholder="City"
              autoComplete="shipping address-level2"
              validator={validateCity}
              errorString="Invalid address"
              callback={useCallback(
                (value: string, isValid: boolean | undefined): void => {
                  dispatch({
                    type: CheckoutContextActions.setFieldValue,
                    field: RegistrationFieldNames.city,
                    value,
                    isValid,
                  });
                },
                [dispatch]
              )}
            />

            <div className="side-by-side-inputs">
              <StatesDropdown
                value={state[RegistrationFieldNames.state].value ?? ''}
                name={RegistrationFieldNames.state}
                autoComplete="shipping address-level1"
                errorString="Invalid state"
                initialPromptValue={INITIAL_DROPDOWN_PROMPT_VALUE}
                validator={(value: string): boolean =>
                  value !== INITIAL_DROPDOWN_PROMPT_VALUE
                }
                callback={useCallback(
                  (value: string, isValid: boolean | undefined): void => {
                    dispatch({
                      type: CheckoutContextActions.setFieldValue,
                      field: RegistrationFieldNames.state,
                      value,
                      isValid,
                    });
                  },
                  [dispatch]
                )}
              />
              <Input
                type="text"
                value={state[RegistrationFieldNames.zip].value ?? ''}
                name={RegistrationFieldNames.zip}
                placeholder="Zip Code"
                autoComplete="shipping postal-code"
                validator={(value: string): undefined | boolean =>
                  validateZip(value)
                }
                errorString="Invalid zip code"
                inputMode="numeric"
                callback={useCallback(
                  (value: string, isValid: boolean | undefined): void => {
                    dispatch({
                      type: CheckoutContextActions.setFieldValue,
                      field: RegistrationFieldNames.zip,
                      value,
                      isValid,
                    });
                  },
                  [dispatch]
                )}
              />
            </div>
          </section>

          <section
            className={clsx(styles.legalSection, {
              [styles.isMobile]: isMobile,
              [styles.isShowing]: legalSectionCondition,
            })}
          >
            <div className={styles.legalWrapper}>
              <Checkbox
                name="acceptedLegals"
                value={state?.acceptedLegals.value}
                callback={useCallback(
                  (value: boolean): void => {
                    dispatch({
                      type: CheckoutContextActions.setFieldValue,
                      field: RegistrationFieldNames.acceptedLegals,
                      value,
                      isValid: value,
                    });
                  },
                  [dispatch]
                )}
              />
              <TermsOfService />
            </div>
            <StripeLogos />
          </section>

          {proceedToShippingButtonCondition && (
            <FormFooter>
              <button
                onClick={proceedToShipping}
                className={styles.ctaButton}
                disabled={!canProceedToShipping || isSubmitting}
              >
                Proceed to shipping
              </button>
            </FormFooter>
          )}

          {!data.error && submitCondition() && (
            <FormFooter>
              <button
                onClick={submit}
                className={styles.ctaButton}
                disabled={!isValidForSubmission && !state.isErrorShowing}
              >
                {isSubmitting && !state.isErrorShowing && (
                  <Spinner color={SPINNER_COLORS.LIGHT} />
                )}
                Submit
              </button>
            </FormFooter>
          )}
          <div ref={shippingSectionRef} />
        </form>
      </div>
    </div>
  );
}
