export function getUrlParam(name: string): string | undefined {
  if (name && window.location.search) {
    const searchParams = getUrlSearchParamsFromWindowLocation();
    const value = searchParams.get(name);
    if (value) {
      return replaceSpaceWithPlus(value);
    }
  }
  return undefined;
}

function getUrlSearchParamsFromWindowLocation(): URLSearchParams {
  const searchParams = isEncoded(window.location.search)
    ? decodeURIComponent(window.location.search)
    : window.location.search;
  return new URLSearchParams(searchParams);
}

function replaceSpaceWithPlus(str: string): string {
  return str.replace(/ /g, '+');
}

function isEncoded(uri: string = ''): boolean {
  try {
    return uri !== decodeURIComponent(uri);
  } catch (err) {
    return false;
  }
}
