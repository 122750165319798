import React from 'react';
import { CheckoutContextActions, useCheckoutContext } from '../context/context';
import { STRINGS } from '../utils/strings';
import styles from './../css/notificationBar.module.scss';

const NotificationBar = React.memo(
  function NotificationBar(): JSX.Element | null {
    const { state, dispatch } = useCheckoutContext();
    return !state.isErrorShowing ? null : (
      <div className={styles.notificationBar}>
        <span
          dangerouslySetInnerHTML={{
            __html: STRINGS.ERRORS.SOMETHING_WRONG_CONTACT_SUPPORT,
          }}
        />
        <button
          onClick={() =>
            dispatch({ type: CheckoutContextActions.dismissError })
          }
          className={styles.dismissErrorButton}
          aria-label="Dismiss notification"
        />
      </div>
    );
  }
);

export default NotificationBar;
