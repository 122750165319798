import mixpanel from 'mixpanel-browser';
import isUUID from 'validator/es/lib/isUUID';
import { mixpanelId } from '../config';
import { getUrlParam } from './query-vars';

export const PROJECT_PREFIX = 'CHECKOUT';
export const REGISTRATION_COMPLETE = `${PROJECT_PREFIX}:registration-complete`;
const PAGE_VIEW = `${PROJECT_PREFIX}:page-view`;

mixpanel.init(mixpanelId);

export function track(event: string, data: any = {}): void {
  const eventData = normalizeEventAttributeValues(data);
  mixpanel.track(event, eventData);
}

export function trackPageViewInMixpanel(
  page: string,
  additionalData?: any
): void {
  const userIdFromUrlParam = getUrlParam('id');

  if (!userIdFromUrlParam) {
    return;
  }

  identifyUser(userIdFromUrlParam);

  const paramOptions: string[] = [
    'source',
    'placement',
    'ad_id',
    'adset_id',
    'campaign_id',
    'ad_name',
    'adset_name',
    'campaign_name',
  ];

  let data: { [key: string]: string } = {
    'Page Path': page.toLowerCase(),
  };

  for (let i = 0; i <= paramOptions.length; i++) {
    const param = getUrlParam(paramOptions[i]);
    if (param) {
      data[paramOptions[i]] = param;
    }
  }

  data['user_id_from_url'] = userIdFromUrlParam;

  if (Boolean(additionalData)) {
    data = { ...data, ...additionalData };
  }

  track(PAGE_VIEW, data);
}

export function registerUserInMixpanel(userSub: string, data: any): void {
  registerUnloadEventForMixpanel();
  const personData = makePersonData(data);
  mixpanel.people.set(personData, () => identifyAndReset(userSub));
  track(REGISTRATION_COMPLETE, personData);
}

export function identifyAndReset(userId: string): void {
  if (userId && isUUID(userId, 4)) {
    mixpanel.identify(userId);
    resetUser();
  }
}

export function identifyUser(userId: string): void {
  if (userId && isUUID(userId, 4)) {
    mixpanel.identify(userId);
  }
}

export function resetUser(): void {
  mixpanel.reset();
}

export function registerUnloadEventForMixpanel(): void {
  window.addEventListener('beforeunload', resetUser);
}

export function getMixpanelDistinctId(): any {
  return mixpanel.get_distinct_id();
}

function makePersonData(data: Record<string, any>): Record<string, any> {
  let personData: Record<string, any> = {};

  for (let key in data.userData) {
    if (data.userData[key].length > 0) {
      personData[`$custom:${key}`] = data.userData[key][0];
    }
  }

  return personData;
}

function normalizeEventAttributeValues(
  eventData: Record<string, any>
): Record<string, any> {
  const data = { ...eventData };
  if (data['Page Path']) {
    data['Page Path'] = data['Page Path'].toLowerCase();
  }
  return data;
}
