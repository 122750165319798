import React from 'react';
import styles from '../css/checkout.module.scss';

type PersonalDetailsCardProps = {
  name: string;
  lastName: string;
  email: string;
  onClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const PersonalDetailsCard = React.memo(function PersonalDetailsCard({
  name,
  lastName,
  email,
  onClickHandler,
}: PersonalDetailsCardProps) {
  return (
    <div className={styles.personalDetails}>
      <span>
        Your Name:{' '}
        <strong>
          {name} {lastName}
        </strong>
      </span>
      <span>
        Your Email: <strong>{email}</strong>
      </span>
      <button onClick={onClickHandler}>Edit</button>
    </div>
  );
});

export default PersonalDetailsCard;
