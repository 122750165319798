import { apiRootEndpoint, stripe as stripeConfig } from '../config';
import { CheckoutContextActions, useCheckoutContext } from '../context/context';
import { PROJECT_PREFIX, track } from '../utils/mixpanel-helper';

// @ts-ignore
const stripe = Stripe(stripeConfig.key); // eslint-disable-line

type StripeCheckoutSession = {
  [key: string]: any;
};

export function useStripePaymentFlow(): Record<string, Function> {
  const { dispatch } = useCheckoutContext();

  const initPaymentFlow = async (state: Record<string, any>): Promise<void> => {
    try {
      const stripeCheckoutSession = await getStripeCheckoutSession(state);
      await redirectToStripe(stripeCheckoutSession);
    } catch (err) {
      dispatch({ type: CheckoutContextActions.showError });
      trackSubmission(false, err as any, state.nicTypes);
    }
  };

  const redirectToStripe = async (
    stripeCheckoutSession: StripeCheckoutSession
  ): Promise<void> => {
    if (stripeCheckoutSession?.sessionUrl?.sessionId) {
      stripe.redirectToCheckout({
        sessionId: stripeCheckoutSession.sessionUrl.sessionId,
      });
    } else {
      dispatch({ type: CheckoutContextActions.showError });
    }
  };

  const getStripeCheckoutSession = async (
    state: Record<string, any>
  ): Promise<any | Error> => {
    const userId = state.userId || state.registeredUserId;
    const {
      nicTypes,
      accessCode,
      firstName,
      stripePriceId,
      utm_source,
      utm_campaign,
      utm_medium,
      utm_content,
      utm_term,
    } = state;

    const paramsForQueryString = {
      user_id: userId,
      price_id: stripePriceId,
      given_name: firstName.value,
      access_code: accessCode,
      ...(utm_source && { utm_source }),
      ...(utm_campaign && { utm_campaign }),
      ...(utm_medium && { utm_medium }),
      ...(utm_content && { utm_content }),
      ...(utm_term && { utm_term }),
    };

    const queryString = Object.entries(paramsForQueryString)
      .reduce((accum, tuple) => {
        accum.push(tuple.join('='));
        return accum;
      }, [] as string[])
      .join('&');

    const productDetails = { stripePriceId, nicTypes, accessCode };

    trackCheckout('', { result: '', productDetails }, nicTypes);

    try {
      const req = await fetch(
        `${apiRootEndpoint}/v1/products/checkout-session`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            priceId: stripePriceId,
            userId: userId,
            successUrl: `${window.location.origin}/activated?${queryString}`,
            cancelUrl: `${window.location.origin}/plan/${stripePriceId}?${queryString}`,
            promoCode: '',
          }),
        }
      );
      const res = await req.json();
      if (res.status !== 'success') {
        throw Error(res);
      }
      return res.data;
    } catch (err) {
      trackCheckout(false, { err, productDetails }, nicTypes);
      throw err;
    }
  };

  const trackSubmission = (
    status: boolean,
    data: Record<string, any> = {},
    nicotineAddictionType: string[]
  ): void => {
    const payload = {
      success: status,
      'Page Path': window.location.pathname,
      nicotineAddictionType,
      ...data,
    };
    track(`${PROJECT_PREFIX}:form-submit`, payload);
  };

  const trackCheckout = (
    status: boolean | string,
    data: Record<string, any> = {},
    nicotineAddictionType: string[]
  ): void => {
    const payload = {
      success: status,
      'Page Path': window.location.pathname,
      nicotineAddictionType,
      ...data,
    };
    track(`${PROJECT_PREFIX}:checkout-stripe`, payload);
  };

  return {
    initPaymentFlow,
    redirectToStripe,
  };
}
