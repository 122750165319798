import clsx from 'clsx';

type TogglePasswordButtonProps = {
  isActive: boolean;
  onClickHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export function TogglePasswordButton({
  isActive,
  onClickHandler,
}: TogglePasswordButtonProps): JSX.Element {
  return (
    <button
      className={clsx('password-toggle-type-button', {
        'is-type-text': isActive,
      })}
      onClick={onClickHandler}
      aria-pressed={isActive}
    >
      {isActive ? (
        <span className="screen-reader-text">Hide password</span>
      ) : (
        <span className="screen-reader-text">Show password</span>
      )}
    </button>
  );
}
