import clsx from 'clsx';
import mailcheck from 'mailcheck';
import React, { useCallback, useEffect, useState } from 'react';
import { checkUserStatus } from '../../utils/check-user-status';
import { USER_EMAIL_STATUS_RES } from '../../utils/constants';
import { STRINGS } from '../../utils/strings';
import { validateEmail } from '../../utils/validation';
import Spinner from '../spinner';
import LabelAndError from './label-and-error';
import { useFieldStates } from './use-field-states';

type EmailInputFieldProps = {
  name: string;
  value: string;
  placeholder: string;
  disabled?: boolean;
  callback: (value: any, isValid: undefined | boolean) => void;
};

export function EmailInputField({
  name,
  placeholder = 'Email',
  value,
  disabled = false,
  callback,
}: EmailInputFieldProps): JSX.Element {
  const [isCallInProgress, setCallInProgress] = useState<boolean>();
  const [message, setMessage] = useState<string>('');
  const [emailSuggestion, setEmailSuggestion] = useState<null | Record<
    string,
    any
  >>();

  const [isValid, setIsValid] = useState<undefined | boolean>();

  const validator = useCallback((): undefined | boolean => {
    return isValid;
  }, [isValid]);

  const { isPristine, onBlurHandler } = useFieldStates({
    validator,
    value,
  });

  const validateEmailFields = useCallback(() => {
    if (!value || value.length === 0) {
      setMessage('');
      setIsValid(undefined);
      return;
    }

    if (!validateEmail(value)) {
      setMessage(STRINGS.ERRORS.EMAIL_NOT_VALID);
      setIsValid(false);
      return;
    }

    setCallInProgress(true);

    checkUserStatus(value).then(res => {
      setCallInProgress(false);
      if (res === USER_EMAIL_STATUS_RES.NOT_FOUND) {
        setMessage('');
        setIsValid(true);
      } else if (res === USER_EMAIL_STATUS_RES.EXISTS) {
        setMessage(STRINGS.ERRORS.EMAIL_EXISTS_IN_SYSTEM);
        setIsValid(false);
      } else if (res === USER_EMAIL_STATUS_RES.NOT_VERIFIED) {
        setMessage(STRINGS.ERRORS.USER_NOT_CONFIRMED);
        setIsValid(false);
      } else if (res === USER_EMAIL_STATUS_RES.NOT_INITIALIZED) {
        setMessage(STRINGS.ERRORS.USER_NOT_INITIALIZED);
        setIsValid(false);
      }
    });
  }, [value]);

  useEffect((): void => {
    validateEmailFields();
  }, [value, validateEmailFields]);

  useEffect((): void => {
    if (callback && value) {
      callback(value, isValid);
    }
  }, [isValid, value, callback]);

  const onUpdateHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const eventValue = e.currentTarget.value.trim();
    callback(eventValue, isValid);
  };

  const checkForTypos = (): void => {
    onBlurHandler();
    mailcheck.run({
      email: value,
      suggested: (suggestion: Record<string, any>) => {
        setEmailSuggestion(suggestion);
      },
      empty: () => {
        setEmailSuggestion(null);
      },
    });
  };

  const replaceEmailWithSuggestion = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
    if (emailSuggestion && emailSuggestion.full) {
      callback(emailSuggestion.full, isValid);
      setEmailSuggestion(null);
    }
  };

  return (
    <>
      <label
        className={clsx('form-input', 'email-input', {
          'is-valid': isValid === true,
          'has-error': isValid === false,
          [isPristine ? 'is-pristine' : 'is-touched']: true, //isPristine === true
        })}
        htmlFor={name}
      >
        <LabelAndError
          required={true}
          isValid={isValid}
          placeholder={placeholder}
          errorString={message}
        />
        <input
          id={name}
          type="email"
          inputMode="email"
          name={name}
          value={value}
          onChange={onUpdateHandler}
          onBlur={checkForTypos}
          autoComplete="email"
          disabled={disabled}
          required
        />
        {isCallInProgress && <Spinner />}
      </label>
      <div className="email-typo-suggestion">
        {emailSuggestion && (
          <>
            Did you mean{' '}
            <button onClick={replaceEmailWithSuggestion}>
              {emailSuggestion.full}
            </button>
            ?
          </>
        )}
      </div>
    </>
  );
}
