import clsx from 'clsx';
import LabelAndError from './label-and-error';
import { useFieldStates } from './use-field-states';

type InputProps = {
  type: string;
  name: string;
  value: string;
  validator: (value?: any) => boolean | undefined;
  placeholder: string;
  autoComplete: string;
  errorString: string;
  required?: boolean;
  inputMode?:
    | 'text'
    | 'search'
    | 'email'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal'
    | undefined;
  callback: (value: any, isValid: undefined | boolean) => void;
};

export default function Input({
  type,
  name,
  value,
  validator,
  placeholder,
  autoComplete,
  errorString,
  required = true,
  inputMode = 'text',
  callback,
}: InputProps): JSX.Element {
  const { validate, isValid, isPristine, onBlurHandler } = useFieldStates({
    validator,
    value,
  });

  const update = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const eventValue = e.currentTarget.value;
    callback(eventValue, validate(eventValue));
  };

  return (
    <label
      className={clsx('form-input', {
        'is-valid': isValid === true,
        'has-error': isValid === false,
        [isPristine ? 'is-pristine' : 'is-touched']: true,
      })}
      htmlFor={name}
    >
      <LabelAndError
        required={required}
        isValid={isValid}
        placeholder={placeholder}
        errorString={errorString}
      />
      <input
        type={type || 'text'}
        inputMode={inputMode}
        id={name}
        name={name}
        value={value}
        autoComplete={autoComplete}
        onChange={update}
        onBlur={onBlurHandler}
        aria-invalid={!isValid}
        required={required}
      />
    </label>
  );
}
