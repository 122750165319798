import React from 'react';
import { ExternalTextLink } from './external-text-link';

const EmailPrivacyNotification = React.memo(
  function EmailPrivacyNotification() {
    return (
      <div className="email-instruction">
        <span className="i-icon" aria-hidden="true">
          i
        </span>
        <div>
          <span>
            Please use the email address that you check most often. You’ll need
            it to log into your account and receive essential emails.
          </span>
          <span>
            I understand that Pivot will be sending some{' '}
            <ExternalTextLink
              label="Personally Identifiable Information"
              href="https://www.hhs.gov/answers/hhs-administrative/what-is-pii/index.html"
            />{' '}
            &{' '}
            <ExternalTextLink
              label="Protected Health Information"
              href="https://www.hhs.gov/answers/hipaa/what-is-phi/index.html"
            />{' '}
            related to my Pivot Program to the above email.
          </span>
        </div>
      </div>
    );
  }
);

export default EmailPrivacyNotification;
