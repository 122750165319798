export function checkFormValidity(
  state: Record<string, any>,
  needsShipping: boolean
): boolean {
  if (!state) {
    return false;
  }

  const { acceptedLegals } = state;

  if (!acceptedLegals.isValid) {
    return false;
  }

  const arePersonalDetailsValid = checkPersonalDetailsValidity(state);

  if (needsShipping) {
    const isShippingAddressValid = checkShippingAddressValidity(state);
    return arePersonalDetailsValid && isShippingAddressValid;
  }

  return arePersonalDetailsValid;
}

export function checkPersonalDetailsValidity(
  state: Record<string, any>
): boolean {
  if (!state) {
    return false;
  }
  const { email, password, firstName, lastName } = state;
  return (
    email.isValid && password.isValid && firstName.isValid && lastName.isValid
  );
}

export function checkShippingAddressValidity(
  state: Record<string, any>
): boolean {
  if (!state) {
    return false;
  }
  const { streetAddress1, city, state: usState, zip } = state;
  return (
    streetAddress1.isValid && city.isValid && usState.isValid && zip.isValid
  );
}
