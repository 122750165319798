import React from 'react';
import styles from '../css/checkout.module.scss';
import { ExternalTextLink } from './external-text-link';

const TermsOfService = React.memo(function TermsOfService() {
  return (
    <p className={styles.legal}>
      Pivot has taken measures to protect your privacy, as described in the{' '}
      <ExternalTextLink
        label="Privacy Policy"
        href="https://pivot.co/privacy-policy/"
      />{' '}
      and{' '}
      <ExternalTextLink
        label="Terms of Service"
        href="https://pivot.co/terms-conditions/"
      />
      . By clicking Submit, you are indicating that you have read and agree to
      these terms.
    </p>
  );
});

export default TermsOfService;
