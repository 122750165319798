import { getUrlParam } from './query-vars';

const UTM_PARAMS = [
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_content',
  'utm_term',
] as const;

export type UtmParamsDictionary = Record<typeof UTM_PARAMS[number], string>;

export function getUtmParams(): UtmParamsDictionary {
  return UTM_PARAMS.reduce((accum, paramName) => {
    const paramValue = getUrlParam(paramName);
    if (paramValue) {
      accum[paramName] = paramValue;
    }
    return accum;
  }, {} as UtmParamsDictionary);
}
