import clsx from 'clsx';
import React, { useId } from 'react';
import styles from '../../css/checkout.module.scss';

type CheckboxProps = {
  name: string;
  value: boolean;
  callback: (value: boolean, isValid?: boolean) => void;
};

export default function Checkbox({ name, value, callback }: CheckboxProps) {
  const id: string = useId();

  const update = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const eventValue = e.currentTarget.checked;
    callback(eventValue);
  };

  return (
    <div className={clsx(styles.checkbox, { [styles.isChecked]: value })}>
      <label htmlFor={`${id}-${name}`}>
        <input
          type="checkbox"
          id={`${id}-${name}`}
          name={name}
          checked={value || false}
          onChange={update}
        />
      </label>
    </div>
  );
}
