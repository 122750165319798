import { apiRootEndpoint } from '../config';
import { USER_EMAIL_STATUS_RES } from './constants';
import { validateEmail } from './validation';

type EmailExistsApiResponse = {
  status: string;
  data: {
    initialized: boolean;
    verified: boolean;
    errorCode?: string;
  };
};

const emailCheckCache = new Map<string, Promise<Response>>();

export async function checkUserStatus(email: string): Promise<string> {
  if (!validateEmail(email)) {
    return USER_EMAIL_STATUS_RES.INVALID_EMAIL;
  }

  // cache handling
  const emailFromCache = emailCheckCache.get(email);
  const emailCheck =
    emailFromCache ??
    fetch(`${apiRootEndpoint}/v3/users/${email}/exists`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

  if (!emailFromCache) {
    emailCheckCache.set(email, emailCheck);
  }

  // api res handling
  return emailCheck
    .then((res): Promise<EmailExistsApiResponse> => res.clone().json())
    .then(async (res: EmailExistsApiResponse): Promise<string> => {
      const data = res.data;
      if (!data || data.errorCode === 'UserNotFoundError') {
        return USER_EMAIL_STATUS_RES.NOT_FOUND;
      }
      if (data.verified && data.initialized) {
        return USER_EMAIL_STATUS_RES.EXISTS;
      }
      if (!data.verified) {
        return USER_EMAIL_STATUS_RES.NOT_VERIFIED;
      }
      if (!data.initialized) {
        return USER_EMAIL_STATUS_RES.NOT_INITIALIZED;
      }
      return USER_EMAIL_STATUS_RES.UNDEFINED;
    });
}
