import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { DEFAULT_MIN_PASSWORD_LENGTH } from '../../utils/constants';
import { validatePassword } from '../../utils/validation';
import { TogglePasswordButton } from './password-button';
import { passwordReducer, PasswordState } from './password-reducer';
import { PasswordStrengthCriteriaContainer } from './password-strength-criteria';

type PasswordAndStrengthProps = {
  name: string;
  value: string;
  callback: (value: string, isValid: boolean) => void;
};

export default function PasswordAndStrength({
  name,
  value: password,
  callback,
}: PasswordAndStrengthProps): JSX.Element {
  const [state, dispatch] = useReducer(passwordReducer, {
    isBlurred: 'pristine',
    hasMinCharLength: null,
    hasUpperCase: null,
    hasLowerCase: null,
    hasDigit: null,
    isShowingPassword: false,
  } as PasswordState);

  const isPasswordValid: boolean = useMemo(() => {
    return validatePassword(password);
  }, [password]);

  const checkPasswordForCriteria = useCallback(() => {
    const passwordCriteria = {
      hasMinCharLength: password.trim().length >= DEFAULT_MIN_PASSWORD_LENGTH,
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasDigit: /\d/.test(password),
    };
    dispatch({ type: 'criteria', value: passwordCriteria });
  }, [password]);

  useEffect(() => {
    if (state.isBlurred !== 'pristine') {
      checkPasswordForCriteria();
    }
    callback(password, isPasswordValid);
  }, [
    password,
    checkPasswordForCriteria,
    isPasswordValid,
    name,
    state.isBlurred,
    callback,
  ]);

  const onBlur = (): void => {
    if (password.length > 0) {
      checkPasswordForCriteria();
    }

    if (password.length === 0) {
      dispatch({ type: 'isBlurred', value: 'pristine' });

      const passwordCriteria = {
        hasMinCharLength: null,
        hasUpperCase: null,
        hasLowerCase: null,
        hasDigit: null,
      };

      dispatch({ type: 'criteria', value: passwordCriteria });
    } else {
      dispatch({ type: 'isBlurred', value: true });
    }
  };

  const onFocus = (): void => {
    dispatch({ type: 'isBlurred', value: 'pristine' });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const eventValue = (e.target as HTMLInputElement).value.trim();
    callback(eventValue, isPasswordValid);

    dispatch({ type: 'isBlurred', value: false });
  };

  const toggleInputType = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    dispatch({ type: 'isShowingPassword', value: !state.isShowingPassword });
  };

  return (
    <div className="password-strength-container">
      <label
        htmlFor="password"
        className={clsx('form-input', {
          'is-blurred': state.isBlurred,
          'is-valid-false': password.length > 0 && !isPasswordValid,
          'is-valid': isPasswordValid,
        })}
      >
        <span className="label">
          Create your password
          <span className="required" />
        </span>
        <input
          id="password"
          type={state.isShowingPassword ? 'text' : 'password'}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          name="password"
          value={password}
          aria-invalid={!isPasswordValid}
        />
      </label>
      <div
        className={clsx('password-strength', {
          'is-pristine': password.length === 0,
          'is-pristine-when-submitted': password.length === 0,
        })}
        role="alert"
      >
        <PasswordStrengthCriteriaContainer
          isPasswordValid={isPasswordValid}
          hasMinCharLength={state.hasMinCharLength}
          hasUpperCase={state.hasUpperCase}
          hasLowerCase={state.hasLowerCase}
          hasDigit={state.hasDigit}
        />
      </div>
      <TogglePasswordButton
        isActive={state.isShowingPassword}
        onClickHandler={toggleInputType}
      />
    </div>
  );
}
