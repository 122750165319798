import { useMatch } from '@tanstack/react-location';
import FormFooter from '../components/form-footer';
import styles from '../css/checkout.module.scss';
import BreatheWithoutCoachingImg from '../media/breathe-without-coaching.png';
import BreatheWithCoachingImg from '../media/breathe-with-coaching.webp';
import { getPlanFromPriceId } from '../utils/stripe-plan-utils';

export type ActivatedRouteMatch = {
  data: {
    given_name?: string;
    price_id?: string;
  };
};

export default function Activated(): JSX.Element {
  const {
    data: { given_name: givenName, price_id: priceId },
  }: ActivatedRouteMatch = useMatch();
  const plan = priceId ? getPlanFromPriceId(priceId) : null;
  const hasCoaching = plan?.features?.coach === true;

  return (
    <div className={styles.activatedPage}>
      <img src={hasCoaching ? BreatheWithCoachingImg : BreatheWithoutCoachingImg} alt="Pivot Breath product" />
      <div className={styles.activatedPageCopy}>
        <h1 className={styles.sectionTitle}>Subscription Activated</h1>
        <p>
          Congratulations{givenName ? ` ${givenName}` : ''}, you have
          successfully activated your Pivot subscription.
        </p>
        <p>You can cancel your subscription at any time from the Pivot app.</p>
        <FormFooter classNames={styles.activatedPageFormFooter}>
          <a
            href="https://app.pivot.co?program=breathe"
            className={styles.ctaButton}
          >
            Get Pivot
          </a>
        </FormFooter>
      </div>
    </div>
  );
}
