import React from 'react';
import styles from '../css/checkout.module.scss';
import type { Product } from '../types/types';
import {
  getPlanActiveFeatures,
  getPlanFromPriceId,
} from '../utils/stripe-plan-utils';

type PlanCardProps = {
  stripePriceId: string;
  product: Product;
};

const PlanCard = React.memo(function PlanCard({
  stripePriceId,
  product,
}: PlanCardProps): JSX.Element {
  const plan = getPlanFromPriceId(stripePriceId);
  const features: string[] = getPlanActiveFeatures(stripePriceId);
  const price = product.price ? `$${product.price / 100}` : null;

  return (
    <div className={styles.planCard}>
      <h3 className={styles.planName}>{plan?.name}</h3>
      <strong className={styles.price}>{price}</strong>
      <span className={styles.planDuration}>
        {product.productSummary?.description}
      </span>
      <ul className={styles.planFeatures}>
        {features.map(feature => (
          <li key={feature}>{feature}</li>
        ))}
      </ul>
      {plan?.requiresTwoMonthMinimum && (
        <em className={styles.planSmallPrint}>
          Requires minimum of 2 months subscription
        </em>
      )}
    </div>
  );
});
export default PlanCard;
