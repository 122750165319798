import { stripe } from '../config';
import { FEATURE_NAMES, PLANS } from '../plans';
import { Plan, UserFeature } from '../types/types';

export function getPlanFromPriceId(priceId: string): Plan | null {
  const planName = stripe.priceIds[priceId];
  return PLANS[planName] || null;
}

export function getPlanActiveFeatures(priceId: string): string[] {
  const plan = getPlanFromPriceId(priceId);
  if (!plan) {
    return [];
  }

  return Object.entries(plan.features).reduce((accum, feature) => {
    const [name, isActive] = feature;
    const featureName: string = FEATURE_NAMES[name as UserFeature];
    if (isActive) {
      accum.push(featureName);
    }
    return accum;
  }, [] as string[]);
}
