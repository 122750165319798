import clsx from 'clsx';
import styles from '../../css/checkout.module.scss';

type PasswordStrengthCriteriaContainerProps = {
  isPasswordValid: boolean;
  hasMinCharLength: null | boolean;
  hasUpperCase: null | boolean;
  hasLowerCase: null | boolean;
  hasDigit: null | boolean;
};

export function PasswordStrengthCriteriaContainer({
  isPasswordValid,
  hasMinCharLength,
  hasUpperCase,
  hasLowerCase,
  hasDigit,
}: PasswordStrengthCriteriaContainerProps): JSX.Element {
  return (
    <>
      <PasswordStrengthCriteria
        isOk={hasMinCharLength}
        message="9 characters"
      />
      <PasswordStrengthCriteria
        isOk={hasUpperCase}
        message="1 uppercase character"
      />
      <PasswordStrengthCriteria
        isOk={hasLowerCase}
        message="1 lowercase character"
      />
      <PasswordStrengthCriteria isOk={hasDigit} message="1 number" />
      {isPasswordValid && (
        <span className="screen-reader-text">Password is now valid</span>
      )}
    </>
  );
}

type PasswordStrengthCriteriaProps = {
  isOk: null | boolean;
  message: string;
};

type CriteriaStateCssClassName =
  | 'criteria-wrong'
  | 'criteria-ok'
  | 'criteria-null';

function PasswordStrengthCriteria({
  isOk,
  message,
}: PasswordStrengthCriteriaProps): JSX.Element {
  const screenReaderPrefixIntro = (
    <div className={styles.passwordMessage}>
      <span className="screen-reader-text">Password needs at least</span>
      {message}
    </div>
  );

  const screenReaderPrefixFail = (
    <div className={styles.passwordMessage}>
      <span className="screen-reader-text">Password is missing at least</span>
      {message}
    </div>
  );

  const criteriaOkMessage = (
    <div className={styles.passwordMessage}>{message}</div>
  );

  let className: CriteriaStateCssClassName;
  let displayMessage;
  switch (isOk) {
    case false:
      className = 'criteria-wrong';
      displayMessage = screenReaderPrefixFail;
      break;
    case true:
      className = 'criteria-ok';
      displayMessage = criteriaOkMessage;
      break;
    case null:
    default:
      className = 'criteria-null';
      displayMessage = screenReaderPrefixIntro;
      break;
  }

  return (
    <div className={clsx('criteria', className)} aria-hidden={Boolean(isOk)}>
      {displayMessage}
    </div>
  );
}
