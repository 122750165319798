import * as React from 'react';
import { BooleanField, Field } from '../types/types';
import { DEFAULT_DTC_ACCESS_CODE } from '../utils/constants';
import { getUrlParam } from '../utils/query-vars';
import { getUtmParams, UtmParamsDictionary } from '../utils/utm-params';

export enum CheckoutContextActions {
  showError = 'showError',
  dismissError = 'dismissError',
  setFieldValue = 'setFieldValue',
  toggleCheckboxValue = 'toggleCheckboxValue',
}

export enum RegistrationFieldNames {
  email = 'email',
  password = 'password',
  firstName = 'firstName',
  lastName = 'lastName',
  streetAddress1 = 'streetAddress1',
  streetAddress2 = 'streetAddress2',
  city = 'city',
  state = 'state',
  zip = 'zip',
  country = 'country',
  acceptedLegals = 'acceptedLegals',
}

export type Action = {
  type: keyof typeof CheckoutContextActions;
};

export type FieldAction = {
  type: keyof typeof CheckoutContextActions;
  field: keyof State;
  value?: string | boolean | null | undefined;
  isValid: undefined | boolean;
};

type Dispatch = (action: Action | FieldAction) => void;

type State = {
  isErrorShowing: boolean;
  email: Field;
  password: Field;
  firstName: Field;
  lastName: Field;
  streetAddress1: Field;
  streetAddress2: Field;
  city: Field;
  state: Field;
  zip: Field;
  country: Field;
  acceptedLegals: BooleanField;
  utm: UtmParamsDictionary;
  accessCode: string;
  registeredUserId: string | undefined;
  stripePriceId: string;
  nicTypes: string[];
};

const initialAppState: State = {
  isErrorShowing: false,
  utm: getUtmParams(),
  nicTypes: [],
  accessCode: DEFAULT_DTC_ACCESS_CODE, // Change this if you'd like to get the access_code from the url on load or redirect from stripe
  stripePriceId: '',
  registeredUserId: getUrlParam('user_id') ?? undefined,
  email: {
    value: '',
    isValid: undefined,
  },
  password: {
    value: '',
    isValid: undefined,
  },
  firstName: {
    value: getUrlParam('given_name') ?? '',
    isValid: undefined,
  },
  lastName: {
    value: '',
    isValid: undefined,
  },
  streetAddress1: {
    value: '',
    isValid: undefined,
  },
  streetAddress2: {
    value: '',
    isValid: undefined,
  },
  city: {
    value: '',
    isValid: undefined,
  },
  state: {
    value: '',
    isValid: undefined,
  },
  zip: {
    value: '',
    isValid: undefined,
  },
  country: {
    value: 'United States',
    isValid: true,
  },
  acceptedLegals: {
    value: false,
    isValid: false,
  },
};

const CheckoutContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function checkoutContextReducer(
  state: State,
  action: Action | FieldAction
): State {
  switch (action.type) {
    case CheckoutContextActions.showError:
      return { ...state, isErrorShowing: true };
    case CheckoutContextActions.dismissError:
      if (state.isErrorShowing) {
        return { ...state, isErrorShowing: false };
      }
      return state;
    case CheckoutContextActions.setFieldValue:
      const fieldAction = action as FieldAction;
      const field = state[fieldAction.field] as Field;
      return {
        ...state,
        [fieldAction.field]: {
          ...field,
          value: fieldAction.value,
          isValid: fieldAction.isValid,
        },
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

type CheckoutProviderProps = { children: React.ReactNode };

export function CheckoutContextProvider({ children }: CheckoutProviderProps) {
  const [state, dispatch] = React.useReducer(
    checkoutContextReducer,
    initialAppState
  );

  // TODO: we *might* need to memoize this value http://kcd.im/optimize-context
  const value = { state, dispatch };
  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
}

export function useCheckoutContext() {
  const context = React.useContext(CheckoutContext);
  if (context === undefined) {
    throw new Error(
      'useCheckoutContext must be used within a CheckoutContextProvider'
    );
  }
  return context;
}
