import { useCallback, useMemo, useState } from 'react';

type UseFieldStatesArgs = {
  validator: (value: any) => undefined | boolean;
  value: any;
};

type useFieldStatesReturn = {
  isPristine: undefined | boolean;
  isValid: undefined | boolean;
  validate: (value: any) => undefined | boolean;
  onBlurHandler: () => void;
};

export function useFieldStates({
  validator,
  value,
}: UseFieldStatesArgs): useFieldStatesReturn {
  const [wasBlurredByUser, setBlurredByUser] = useState<boolean>(false);

  const isPristine = useMemo((): undefined | boolean => {
    if (!wasBlurredByUser) {
      return true;
    }
    if (value !== '') {
      return false;
    }
    if (value === '') {
      return true;
    }
  }, [value, wasBlurredByUser]);

  const validate = useCallback(
    (realTimeValue: any): undefined | boolean => {
      return Boolean(validator) ? validator(realTimeValue) : true;
    },
    [validator]
  );

  const isValid = useMemo((): undefined | boolean => {
    if (!wasBlurredByUser) {
      return undefined;
    }
    if (!isPristine || value?.length > 0) {
      return validate(value);
    }
  }, [value, wasBlurredByUser, isPristine, validate]);

  const onBlurHandler = (): void => {
    if (value !== '') {
      setBlurredByUser(true);
    }
  };

  return { onBlurHandler, isValid, isPristine, validate };
}
