import { PLAN_NAMES } from './plans';

const allEnvironments: Record<string, any> = {
  local: {
    mixpanelId: '220f5dfe32421d88c2b74c268a15c331',
    googleAnalyticsId: 'UNKNOWN',
    apiRootEndpoint: 'https://localhost:3000',
    branchKey: 'key_test_mgE0AkbFqyIR8Yo3XdayIpfeCym9j0TW',
    braze: {
      key: 'c8fa5c99-8956-4d3f-86ba-6f242894cb6b',
      baseUrl: 'sdk.iad-02.braze.com',
    },
    stripe: {
      key: 'pk_test_HvkyKknFXghcrgT8aCWhAFnN',
      priceIds: {
        price_1LG6smLlsTihNvnMXB9IIDPc: PLAN_NAMES.BASIC,
        price_1LIEWOLlsTihNvnM4XmFwJ20: PLAN_NAMES.BASIC, // for vape/other
        price_1LG6tKLlsTihNvnMBLtAl9s3: PLAN_NAMES.BREATH_SENSOR_PLUS,
        price_1MbW3CLlsTihNvnMOvkiBjrA: PLAN_NAMES.BREATH_SENSOR_YEARLY,
        price_1LG6tbLlsTihNvnMz1ca7aQ2: PLAN_NAMES.COACHING_PLUS,
        price_1LIEWiLlsTihNvnMaS6abNS9: PLAN_NAMES.COACHING_PLUS, // for vape/other
        price_1LG6tsLlsTihNvnM64Pf5j2u: PLAN_NAMES.PREMIUM,
      },
    },
  },
  sandbox01: {
    mixpanelId: '220f5dfe32421d88c2b74c268a15c331',
    googleAnalyticsId: 'UNKNOWN',
    apiRootEndpoint: 'https://api-sandbox01.carrotsense.com',
    branchKey: 'key_test_mgE0AkbFqyIR8Yo3XdayIpfeCym9j0TW',
    braze: {
      key: 'c8fa5c99-8956-4d3f-86ba-6f242894cb6b',
      baseUrl: 'sdk.iad-02.braze.com',
    },
    stripe: {
      key: 'pk_test_HvkyKknFXghcrgT8aCWhAFnN',
      priceIds: {
        price_1LG6smLlsTihNvnMXB9IIDPc: PLAN_NAMES.BASIC,
        price_1LIEWOLlsTihNvnM4XmFwJ20: PLAN_NAMES.BASIC, // for vape/other
        price_1LG6tKLlsTihNvnMBLtAl9s3: PLAN_NAMES.BREATH_SENSOR_PLUS,
        price_1MbW3CLlsTihNvnMOvkiBjrA: PLAN_NAMES.BREATH_SENSOR_YEARLY,
        price_1LG6tbLlsTihNvnMz1ca7aQ2: PLAN_NAMES.COACHING_PLUS,
        price_1LIEWiLlsTihNvnMaS6abNS9: PLAN_NAMES.COACHING_PLUS, // for vape/other
        price_1LG6tsLlsTihNvnM64Pf5j2u: PLAN_NAMES.PREMIUM,
      },
    },
  },
  sandbox04: {
    mixpanelId: '220f5dfe32421d88c2b74c268a15c331',
    googleAnalyticsId: 'UNKNOWN',
    apiRootEndpoint: 'https://api-sandbox04.carrotsense.com',
    branchKey: 'key_test_mgE0AkbFqyIR8Yo3XdayIpfeCym9j0TW',
    braze: {
      key: 'c8fa5c99-8956-4d3f-86ba-6f242894cb6b',
      baseUrl: 'sdk.iad-02.braze.com',
    },
    stripe: {
      key: 'pk_test_HvkyKknFXghcrgT8aCWhAFnN',
      priceIds: {
        price_1LG6smLlsTihNvnMXB9IIDPc: PLAN_NAMES.BASIC,
        price_1LIEWOLlsTihNvnM4XmFwJ20: PLAN_NAMES.BASIC, // for vape/other
        price_1LG6tKLlsTihNvnMBLtAl9s3: PLAN_NAMES.BREATH_SENSOR_PLUS,
        price_1MbW3CLlsTihNvnMOvkiBjrA: PLAN_NAMES.BREATH_SENSOR_YEARLY,
        price_1LG6tbLlsTihNvnMz1ca7aQ2: PLAN_NAMES.COACHING_PLUS,
        price_1LIEWiLlsTihNvnMaS6abNS9: PLAN_NAMES.COACHING_PLUS, // for vape/other
        price_1LG6tsLlsTihNvnM64Pf5j2u: PLAN_NAMES.PREMIUM,
      },
    },
  },
  qa01: {
    mixpanelId: '220f5dfe32421d88c2b74c268a15c331',
    googleAnalyticsId: 'UNKNOWN',
    apiRootEndpoint: 'https://api-qa01.carrotsense.com',
    branchKey: 'key_test_mgE0AkbFqyIR8Yo3XdayIpfeCym9j0TW',
    braze: {
      key: 'c8fa5c99-8956-4d3f-86ba-6f242894cb6b',
      baseUrl: 'sdk.iad-02.braze.com',
    },
    stripe: {
      key: 'pk_test_HvkyKknFXghcrgT8aCWhAFnN',
      priceIds: {
        price_1LG6smLlsTihNvnMXB9IIDPc: PLAN_NAMES.BASIC,
        price_1LIEWOLlsTihNvnM4XmFwJ20: PLAN_NAMES.BASIC, // for vape/other
        price_1LG6tKLlsTihNvnMBLtAl9s3: PLAN_NAMES.BREATH_SENSOR_PLUS,
        price_1MbW3CLlsTihNvnMOvkiBjrA: PLAN_NAMES.BREATH_SENSOR_YEARLY,
        price_1LG6tbLlsTihNvnMz1ca7aQ2: PLAN_NAMES.COACHING_PLUS,
        price_1LIEWiLlsTihNvnMaS6abNS9: PLAN_NAMES.COACHING_PLUS, // for vape/other
        price_1LG6tsLlsTihNvnM64Pf5j2u: PLAN_NAMES.PREMIUM,
      },
    },
  },
  production: {
    mixpanelId: '0c5522c377066bf21d7ba2861011cdae',
    googleAnalyticsId: 'G-NQ6FXM4ZPJ',
    apiRootEndpoint: 'https://api.carrotsense.com',
    branchKey: 'key_live_ndvXzbeAuqJI60h8HX4IKndiDqm5jXTI',
    braze: {
      key: '118aaf50-0917-41d4-973a-cf7b8c9ec626',
      baseUrl: 'sdk.iad-02.braze.com',
    },
    stripe: {
      key: 'pk_live_O38jSLGz1ALM94Vo1WNeMPge',
      priceIds: {
        price_1LJ2bTLlsTihNvnMqKxTMIrb: PLAN_NAMES.BASIC,
        price_1LJ2bjLlsTihNvnMsfWfQIi4: PLAN_NAMES.BASIC, // for vape/other
        price_1LJ2bYLlsTihNvnM4yl962YJ: PLAN_NAMES.BREATH_SENSOR_PLUS,
        price_1MhOl1LlsTihNvnMITR4pVnd: PLAN_NAMES.BREATH_SENSOR_YEARLY,
        price_1LJ2bcLlsTihNvnMouHklP7A: PLAN_NAMES.COACHING_PLUS,
        price_1LJ2bmLlsTihNvnMBcQIjHWm: PLAN_NAMES.COACHING_PLUS, // for vape/other
        price_1LJ2bgLlsTihNvnMhYtynJBK: PLAN_NAMES.PREMIUM,
      },
    },
  },
};

const environmentConfig = Object.assign(
  {
    // Place environment variables that have common values here
  },
  allEnvironments[process.env.REACT_APP_ENV || 'local']
);

// Common config
export const {
  mixpanelId,
  googleAnalyticsId,
  apiRootEndpoint,
  branchKey,
  braze,
  stripe,
} = environmentConfig;
