import { apiRootEndpoint } from '../config';
import {
  getMixpanelDistinctId,
  registerUserInMixpanel,
} from '../utils/mixpanel-helper';

import { CheckoutContextActions, useCheckoutContext } from '../context/context';
import type { ShippingAddressFormFields } from '../types/types';
import {
  BRAZE_EVENTS,
  changeBrazeUser,
  getBrazeDeviceId,
  logBrazeEvent,
} from '../utils/braze-helper';
import { trackRegistrationMilestone } from '../utils/google-analytics-helper';

export default function useCreateAccount(): Record<string, Function> {
  const { dispatch } = useCheckoutContext();

  const createAccount = async (state: Record<string, any>): Promise<void> => {
    const { utm } = state.utm;
    const shippingAddress = getShippingAddress(state);
    const brazeDeviceId = await getBrazeDeviceId();

    const requestBody = {
      username: state.email.value,
      password: state.password.value,
      givenName: state.firstName.value,
      accessCode: state.accessCode,
      optionalUserInfo: {
        familyName: state.lastName.value,
        nicotineTypes: state.nicTypes,
        ...{ shippingAddress },
      },
      mpDistinctId: getMixpanelDistinctId(),
      brazeDeviceId,
      ...{ utm },
    };

    try {
      dispatch({ type: CheckoutContextActions.dismissError });
      const req = await fetch(`${apiRootEndpoint}/v3/registration/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      const res = await req.json();
      const userId = res.data?.userId;
      if (res.status !== 'success' && !userId) {
        throw Error(res);
      }
      trackUser(userId, requestBody);
      return userId;
    } catch (err) {
      dispatch({ type: CheckoutContextActions.showError });
      console.log(err);
    }
  };

  const getShippingAddress = ({
    streetAddress1,
    streetAddress2,
    city,
    state,
    zip,
    country,
  }: ShippingAddressFormFields) => {
    if (
      streetAddress1.value &&
      city.value &&
      state.value &&
      zip.value &&
      country.value
    ) {
      return {
        streetAddressLine1: streetAddress1.value,
        streetAddressLine2: streetAddress2.value,
        city: city.value,
        state: state.value,
        postalCode: zip.value,
        country: country.value,
      };
    }
    return undefined;
  };

  const trackUser = (
    userId: string,
    requestBody: Record<string, any>
  ): void => {
    registerUserInMixpanel(userId, requestBody);
    logBrazeEvent(BRAZE_EVENTS.REGISTERED);
    changeBrazeUser(userId);
    trackRegistrationMilestone(
      requestBody.accessCode,
      requestBody.utm?.utm_source,
      requestBody.utm?.utm_campaign
    );
  };

  return {
    createAccount,
  };
}
