export const STRINGS: Record<string, Record<string, string>> = {
  ERRORS: {
    EMAIL_EXISTS_IN_SYSTEM: `Email is already in system. <a href="https://account.pivot.co/login" class="green">Log in</a>`,
    INVALID_INPUT: 'Input is not valid',
    MISSING: 'Please complete this field',
    SOMETHING_WRONG: 'Oops, looks like there was an error',
    SOMETHING_WRONG_CONTACT_SUPPORT:
      'Oops, looks like there was an error. Please reach out to <a href="mailto:support@pivot.co">support@pivot.co</a> for help',
    USER_NOT_INITIALIZED:
      'You already have an account. Please log in to the mobile app to complete registration.',
    USER_NOT_CONFIRMED:
      'You&apos;ve already signed up, please verify your email.',
    EMAIL_NOT_VALID: 'Email is not a valid address',
  },
  WCAG: {
    SUBMIT_BUTTON_DISABLED:
      'Submit button has been disabled until all fields are completed',
  },
};
