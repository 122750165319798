import clsx from 'clsx';
import { STATES } from '../../utils/constants';
import LabelAndError from './label-and-error';
import { useFieldStates } from './use-field-states';

type StatesDropdownProps = {
  name: string;
  value: string;
  errorString: string;
  excludedStates?: string[];
  autoComplete: string;
  callback: any;
  initialPromptValue?: string;
  validator: (value?: any) => boolean | undefined;
};

export const INITIAL_DROPDOWN_PROMPT_VALUE = 'false';

export default function StatesDropdown({
  name,
  value,
  errorString,
  excludedStates = [],
  callback,
  autoComplete,
  initialPromptValue = INITIAL_DROPDOWN_PROMPT_VALUE,
  validator,
}: StatesDropdownProps): JSX.Element {
  const { validate, isValid, isPristine, onBlurHandler } = useFieldStates({
    validator,
    value,
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const eventValue = e.currentTarget.value;
    callback(eventValue, validate(eventValue));
  };

  return (
    <label
      className={clsx('form-input', {
        'is-valid': isValid === true,
        'has-error': isValid === false,
        [isPristine ? 'is-pristine' : 'is-touched']: true,
        'is-default-value': value === initialPromptValue,
      })}
      htmlFor={name}
    >
      <LabelAndError
        required={true}
        isValid={isValid}
        placeholder="State"
        errorString={errorString}
      />
      <select
        name={name}
        id={name}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        defaultValue={value}
        required
        autoComplete={autoComplete}
      >
        <StatesOptionsMapper
          initialPromptValue={initialPromptValue}
          excludedStates={excludedStates}
        />
      </select>
    </label>
  );
}

function StatesOptionsMapper({
  initialPromptValue = 'false',
  excludedStates = [] as string[],
}): JSX.Element {
  return (
    <>
      <option key="-1" value={initialPromptValue} />
      {Object.keys(STATES).map(state => {
        if (!excludedStates.includes(state)) {
          return (
            <option key={state} value={state}>
              {STATES[state]}
            </option>
          );
        }
        return null;
      })}
    </>
  );
}
