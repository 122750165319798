import clsx from 'clsx';
import React, { useLayoutEffect, useRef, useState } from 'react';
import styles from '../css/formFooter.module.scss';

export function useIsPinned(ref: React.RefObject<any>): boolean {
  const [isPinned, setPinned] = useState<boolean>(false);

  useLayoutEffect(() => {
    let observer: IntersectionObserver;
    if (ref?.current) {
      observer = new IntersectionObserver(
        ([e]) => {
          setPinned(() => e.intersectionRatio < 1);
        },
        {
          threshold: [1],
        }
      );
      observer.observe(ref.current);
    }

    return () => observer && observer.disconnect();
  }, [ref]);

  return isPinned;
}

type FormFooterProps = {
  classNames?: string;
  children: JSX.Element;
};

export default function FormFooter(props: FormFooterProps): JSX.Element {
  const guideRef = useRef<HTMLDivElement>(null);
  const isPinned = useIsPinned(guideRef);
  const cssClassNames = props?.classNames || '';
  return (
    <>
      <div
        className={clsx(styles.formFooter, cssClassNames, {
          [styles.isPinned]: isPinned,
        })}
      >
        {props.children}
      </div>
      <div className={styles.bottomPinRef} ref={guideRef} />
    </>
  );
}
