import type { User } from '@braze/web-sdk';
import * as braze from '@braze/web-sdk';
import { braze as brazeConfig } from '../config';
import type { StringDictionary } from '../types/types';

const BRAZE_PREFIX = 'pivot_checkout_';

export const BRAZE_EVENTS: StringDictionary = {
  CLICKED_CTA: `${BRAZE_PREFIX}clicked_cta`,
  ELIGIBILITY_SUBMITTED: `${BRAZE_PREFIX}eligibility_submitted`,
  ELIGIBILITY_PASSED: `${BRAZE_PREFIX}eligibility_passed`,
  // no prefix, so we can track the 'registered' event consistently across our apps,
  // so we can exclude users from marketing emails
  REGISTERED: 'registered',
};

export const BRAZE_IDENTIFIERS: StringDictionary = {
  EMAIL_ADDRESS: 'email_address',
  MARKETING_ID: 'marketing_id',
  DEVICE_ID: 'device_id',
};

export const BRAZE_ATTRIBUTES: StringDictionary = {
  // native
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  DATE_OF_BIRTH: 'dob',
  GENDER: 'gender',
  PHONE: 'phone',
  EMAIL: 'email',
  // custom
  ACCESS_CODE: 'access_code',
  UTM_SOURCE: 'utm_source',
  UTM_MEDIUM: 'utm_medium',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_CONTENT: 'utm_content',
  UTM_TERM: 'utm_term',
  TOBACCO_TYPES: 'tobacco_types',
  SHIPPING_ADDRESS_LINE_1: 'shipping_address_line_1',
  SHIPPING_ADDRESS_LINE_2: 'shipping_address_line_2',
  SHIPPING_ADDRESS_CITY: 'shipping_address_city',
  SHIPPING_ADDRESS_STATE: 'shipping_address_state',
  SHIPPING_ADDRESS_ZIP: 'shipping_address_zip',
};

export const BRAZE_GENDERS: StringDictionary = {
  FEMALE: 'F',
  MALE: 'M',
  OTHER: 'O',
};

braze.initialize(brazeConfig.key, {
  baseUrl: brazeConfig.baseUrl,
});

braze.openSession();

export const trackPageViewInBraze = (path: string): void => {
  logBrazeEvent(BRAZE_PREFIX + 'page_view', { path });
  setBrazeAttribute(BRAZE_PREFIX + 'last_page_viewed', path);
};

export const logBrazeEvent = (
  eventName: string,
  eventProperties = {}
): void => {
  braze.logCustomEvent(eventName, eventProperties);
};

export const setBrazeAttribute = (
  key: string,
  value: any,
  addToArray = false
): void => {
  const user: User | undefined = braze.getUser();

  if (!user) {
    return;
  }

  switch (key) {
    case BRAZE_ATTRIBUTES.FIRST_NAME:
      user.setFirstName(value);
      break;
    case BRAZE_ATTRIBUTES.LAST_NAME:
      user.setLastName(value);
      break;
    case BRAZE_ATTRIBUTES.DATE_OF_BIRTH:
      const { year, month, day } = value;
      user.setDateOfBirth(year, month, day);
      break;
    case BRAZE_ATTRIBUTES.GENDER:
      user.setGender(value);
      break;
    case BRAZE_ATTRIBUTES.PHONE:
      user.setPhoneNumber(value);
      break;
    case BRAZE_ATTRIBUTES.EMAIL:
      user.setEmail(value);
      break;
    default:
      if (addToArray) {
        user.addToCustomAttributeArray(key, value);
      } else {
        user.setCustomUserAttribute(key, value);
      }
  }
};

export const getBrazeDeviceId = (): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    braze.getDeviceId(deviceId => {
      if (deviceId) {
        resolve(deviceId);
      } else {
        reject();
      }
    });
  });
};

export const changeBrazeUser = (userId: string): void => {
  braze.changeUser(userId);
};
