import isEmail from 'validator/es/lib/isEmail';
import isPostalCode from 'validator/es/lib/isPostalCode';
import { DEFAULT_MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from './constants';

export function validatePassword(
  password: string,
  minLength: number = DEFAULT_MIN_PASSWORD_LENGTH
): boolean {
  if (!password) {
    return false;
  }
  const value = password.trim();
  return (
    /[A-Z]/.test(value) &&
    /[a-z]/.test(value) &&
    /\d/.test(value) &&
    value.length >= minLength &&
    value.length <= MAX_PASSWORD_LENGTH
  );
}

export function validateEmail(email: string): boolean {
  if (!email) {
    return false;
  }
  const value = email.trim();
  return isEmail(value);
}

export function validateName(
  name: string,
  minLength: number = 0,
  maxLength: number = 16
): boolean {
  if (!name) {
    return false;
  }
  const value = name.trim();
  // eslint-disable-next-line no-useless-escape
  const lettersOnly = /^([^!"#$%&\(\)*+./0-9:;<=>?@\[\\\]\^_`\{\}\|\~])*$/;
  return (
    lettersOnly.test(value) &&
    value.length >= minLength &&
    value.length <= maxLength
  );
}

export function validateZip(zip: string): boolean {
  if (!zip) {
    return false;
  }
  const value = zip.trim();
  return isPostalCode(value, 'US');
}

export function validateStreetAddress(address: string): boolean {
  if (!address) {
    return false;
  }
  const value = address.trim();
  // using lookahead assertions to check for their existence of any digit and any letter with specific order (fix for PO Box addresses)
  return /^(?=.*[a-zA-Z])(?=.*[0-9])/.test(value);
}

export function validateCity(city: string): boolean {
  if (!city) {
    return false;
  }
  const value = city.trim();
  // eslint-disable-next-line no-useless-escape
  return /^[a-zA-Z\u0080-\u024F]+(?:([\ \-\']|(\.\ ))[a-zA-Z\u0080-\u024F]+)*$/gim.test(
    value
  );
}

export function validateMaxStringLength(s: string, numChars: number): boolean {
  return Boolean(s) && s.trim().length <= numChars;
}
