import styles from '../css/checkout.module.scss';
import BreatheWithCoachingImg from '../media/breathe-with-coaching.webp';

export default function ErrorPage(): JSX.Element {
  return (
    <div className={styles.activatedPage}>
      <img src={BreatheWithCoachingImg} alt="" />
      <div className={styles.activatedPageCopy}>
        <h1 className={styles.sectionTitle}>Something went wrong..</h1>
      </div>
    </div>
  );
}
