import type { Plan, StringDictionary, UserFeature } from './types/types';

export const FEATURE_NAMES: Record<UserFeature, string> = {
  app: 'Mobile App',
  community: 'Community',
  sensor: 'Breath Sensor',
  coach: 'Dedicated Coach',
};

export const PLAN_NAMES: StringDictionary = {
  BASIC: 'BASIC',
  BREATH_SENSOR_PLUS: 'BREATH_SENSOR_PLUS',
  BREATH_SENSOR_YEARLY: 'BREATH_SENSOR_YEARLY',
  COACHING_PLUS: 'COACHING_PLUS',
  PREMIUM: 'PREMIUM',
} as const;

export const PLANS: Record<string, Plan> = {
  [PLAN_NAMES.BASIC]: {
    name: 'Pivot Basic',
    features: {
      app: true,
      community: true,
      sensor: false,
      coach: false,
    },
    requiresTwoMonthMinimum: false,
  },
  [PLAN_NAMES.BREATH_SENSOR_PLUS]: {
    name: 'Pivot Breath Sensor +',
    features: {
      app: true,
      community: true,
      sensor: true,
      coach: false,
    },
    requiresTwoMonthMinimum: true,
  },
  [PLAN_NAMES.BREATH_SENSOR_YEARLY]: {
    name: 'Pivot Breath Sensor',
    features: {
      app: true,
      community: true,
      sensor: true,
      coach: false,
    },
    requiresTwoMonthMinimum: false,
  },
  [PLAN_NAMES.COACHING_PLUS]: {
    name: 'Pivot Coaching +',
    features: {
      app: true,
      community: true,
      sensor: false,
      coach: true,
    },
    requiresTwoMonthMinimum: true,
  },
  [PLAN_NAMES.PREMIUM]: {
    name: 'Pivot Premium',
    features: {
      app: true,
      community: true,
      sensor: true,
      coach: true,
    },
    requiresTwoMonthMinimum: true,
  },
};
