export type PasswordState = {
  isBlurred: string | boolean;
  hasMinCharLength: null | boolean;
  hasUpperCase: null | boolean;
  hasLowerCase: null | boolean;
  hasDigit: null | boolean;
  isShowingPassword: boolean;
};

export type PasswordActionType =
  | 'isBlurred'
  | 'isDirty'
  | 'isShowingPassword'
  | 'criteria'
  | 'hasMinCharLength'
  | 'hasUpperCase'
  | 'hasLowerCase'
  | 'hasDigit';

type PasswordAction = {
  type: PasswordActionType;
  value: any;
};

export function passwordReducer(
  state: PasswordState,
  action: PasswordAction
): PasswordState {
  switch (action.type) {
    case 'criteria':
      const criteria = action.value;
      return { ...state, ...criteria };
    case 'hasMinCharLength':
    case 'isBlurred':
    case 'isShowingPassword':
    case 'hasUpperCase':
    case 'hasLowerCase':
    case 'hasDigit':
      return { ...state, [action.type]: action.value };
    default:
      return { ...state };
  }
}
