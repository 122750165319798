import React from 'react';
import { STRINGS } from '../../utils/strings';

type LabelAndErrorProps = {
  placeholder: string;
  required: boolean;
  isValid: boolean | undefined;
  errorString: string;
};

const LabelAndError = React.memo(function LabelAndError({
  placeholder,
  required,
  isValid,
  errorString,
}: LabelAndErrorProps) {
  return (
    <div className="label-and-error">
      <span className="label">{placeholder}</span>

      {required && <span className="required" />}

      <span
        aria-live="assertive"
        aria-atomic="true"
        role="alert"
        id={`${placeholder}-alert`}
        className="input-error-message"
        dangerouslySetInnerHTML={{
          __html:
            isValid === false ? errorString || STRINGS.ERRORS.MISSING : '',
        }}
      />
    </div>
  );
});

export default LabelAndError;
