import React from 'react';

type ExternalTextLinkProps = {
  label: string;
  href: string;
  className?: string;
};

export const ExternalTextLink = React.memo(function ExternalTextLink({
  label,
  href,
  className,
}: ExternalTextLinkProps) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={className || ''}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: label,
        }}
      />
      <span className="screen-reader-text">
        External link opens in a new tab
      </span>
    </a>
  );
});
