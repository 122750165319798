import React from 'react';
import styles from '../css/checkout.module.scss';
import StripeLogosImg from '../media/stripe-logos.webp';

const StripeLogos = React.memo(function StripeLogos() {
  return (
    <div className={styles.stripeLogos}>
      <img
        src={StripeLogosImg}
        alt="Checkout with Stripe: Guaranteed safe & secure checkout. You can use Mastercard, Visa, American Express, JCB or Discover cards"
      />
    </div>
  );
});

export default StripeLogos;
