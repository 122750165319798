import ReactGA from 'react-ga4';
import { googleAnalyticsId } from '../config';

ReactGA.initialize(googleAnalyticsId);

export function trackPageViewInGA(path: string): void {
  ReactGA.send({ hitType: 'pageview', page: path });
}

export function trackRegistrationMilestone(
  accessCode: string,
  utmSource: string,
  utmCampaign: string
): void {
  ReactGA.event(
    {
      category: 'Registration',
      action: 'CompleteRegistration',
    },
    {
      utmSource,
      utmCampaign,
    }
  );

  ReactGA.event(
    {
      category: 'Registration',
      action: 'CompleteRegistrationByAccessCode',
    },
    {
      accessCode,
    }
  );
}
